@import '~animate.css/animate.min.css';

@import "chartist/chartist";

@import "~bootstrap/scss/bootstrap";

@import "theme/variables";
@import "style";
@import "theme/responsive";

@import "todo/todo";
@import '~ngx-toastr/toastr.css';
@import 'sweetalert/sweetalert2.scss';
@import "~dragula/dist/dragula.css";
@import "sticky/sticky.scss";
@import '~dropzone/dist/min/dropzone.min.css';
@import '~leaflet/dist/leaflet.css';

